<template>
	<div class="register pos-rel">
		<div class="main pos-center">
			<div class="top flex jc-between">
				<div>
					<img class="mt-30 ml-30" src="../../assets/osImg/02daa407ebf2852d208fbd70968026d.png" alt="" />
				</div>

				<div class="login">
					<router-link to="/login">使用已有账户登录</router-link>
				</div>
			</div>

			<div class="step flex ai-center">
				<div class="steps">
					<el-steps :active="active" finish-status="success">
						<el-step title="填写账户名"></el-step>
						<el-step title="完善信息"></el-step>
						<el-step title="等待审核"></el-step>
					</el-steps>
				</div>
			</div>
			<!-- 第一步 -->
			<div class="fromBox pos-rel" v-if="active == 0">
				<div class="from pos-center">
					<el-form ref="form" :model="form" :rules="rules" label-width="80px">
						<el-form-item prop="name">
							<el-input placeholder="设置用户名称" v-model="form.name">
								<i slot="prefix" class="el-icon-user"></i>
							</el-input>
						</el-form-item>
						<el-form-item prop="mobile">
							<el-input placeholder="输入手机号码" v-model="form.mobile">
								<i slot="prefix" class="el-icon-mobile-phone"></i>
							</el-input>
						</el-form-item>
						<el-form-item class="flex duanxin" prop="smsCode">
							<el-input placeholder="短信验证码" v-model="form.smsCode">
								<i slot="prefix" class="el-icon-chat-dot-round"></i>
							</el-input>

							<el-button @click="getSms()">{{smsText}}</el-button>
						</el-form-item>
						<el-form-item prop="pwd">
							<el-input placeholder="设置6至20位登录密码" v-model="form.pwd">
								<i slot="prefix" class="el-icon-lock"></i>
							</el-input>
						</el-form-item>
						<el-form-item prop="pwd1">
							<el-input placeholder="请再次输入登录密码" v-model="form.pwd1">
								<i slot="prefix" class="el-icon-lock"></i>
							</el-input>
						</el-form-item>
					</el-form>
					<div>
						<el-checkbox v-model="checked">已阅读并同意
							<span class="text">《用户服务协议》</span>
						</el-checkbox>
					</div>
					<div class="text-center mt-10">
						<el-button type="primary" @click="nextSubmit('form')">提交并下一步</el-button>
					</div>
				</div>
			</div>
			<!-- 第二步 -->
			<div class="fromBox pos-rel" v-if="active == 1">
				<div class="from pos-center">
					<el-form ref="form1" :model="form1" label-width="80px">
						<el-form-item>
							<el-input placeholder="输入真实姓名" v-model="form1.realName">
								<i slot="prefix" class="el-icon-user"></i>
							</el-input>
						</el-form-item>
						<el-form-item>
							<el-input placeholder="输入身份证号码" v-model="form1.idCard">
								<i slot="prefix" class="el-icon-bank-card"></i>
							</el-input>
						</el-form-item>

						<el-form-item>
							<el-cascader class="cascader" v-model="provinces" clearable placeholder="请选择省/市/区"
								@change="CityChange" :props="{ value: 'name', label: 'name', children: 'child' }"
								:options="cityOptions">
								<i slot="prefix" class="el-icon-location-outline"></i>
							</el-cascader>
						</el-form-item>
						<el-form-item>
							<el-input placeholder="详细地址" v-model="form1.address">
								<i slot="prefix" class="el-icon-s-home"></i>
							</el-input>
						</el-form-item>
					</el-form>
					<div class="flex">
						<div class="text-center">
							<el-upload class="avatar-uploader" :action="baseURL + '/base-api/file/upload'"
								:on-success="handleAvatarSuccess" :show-file-list="false">
								<img v-if="imageUrl" :src="imageUrl" class="avatar" />
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
							上传头像图片
						</div>
					</div>

					<div class="text-center mt-20">
						<el-button type="primary" @click="submit('form1')">提交审核</el-button>
					</div>
				</div>
			</div>
			<!-- 第三步 -->
			<div class="fromBox pos-rel" v-if="active == 2">
				<div class="from pos-center text-center">
					<i class="el-icon-circle-check"></i>
					<div class="textSubim mt-30">恭喜资料提交成功</div>
					<div class="mt-10">我们将在1-3个工作日内完成审核</div>
					<div class="text-center mt-80">
						<el-button type="primary" @click="next">进入首页</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import caches from "@cache/cache.js"
	import configApi from '@config/configApi'
	var timer;
	export default {
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请输入密码"));
				} else {
					if (this.form.pwd1 !== "") {
						this.$refs.form.validateField("pwd1");
					}
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请再次输入密码"));
				} else if (value !== this.form.pwd) {
					callback(new Error("两次输入密码不一致!"));
				} else {
					callback();
				}
			};
			return {
				active: 0,
				times: 60,
				smsText: '获取验证码',
				provinces: [],
				cityOptions: [],
				form1: {
					address: "",
					city: "",
					head: "",
					id: "",
					idCard: "",
					realName: "",
					id: ""
				},
				imageUrl: '',
				form: {
					name: "",
					mobile: "",
					smsCode: "",
					pwd: "",
					pwd1: "",
				},
				rules: {
					name: [{
						required: true,
						message: "请输入名字",
						trigger: "blur"
					}],
					agentName: [{
						required: true,
						message: "请输入经办人名字",
						trigger: "blur"
					}, ],
					agentIdCard: [{
						required: true,
						message: "请输入身份证号",
						trigger: "blur"
					}, ],
					mobile: [{
						required: true,
						message: "请输入手机号码",
						trigger: "blur"
					}, ],
					smsCode: [{
						required: true,
						message: "请输入验证码",
						trigger: "blur"
					}],
					pwd: [{
						validator: validatePass,
						trigger: "blur"
					}],
					pwd1: [{
						validator: validatePass2,
						trigger: "blur"
					}],
				},
				checked: true,
			};
		},
		methods: {
			nextSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$post("/user-api/register/exp-register", this.form).then(
							(data) => {
								if (data.code == 1000) {
									this.active = this.active + 1;
									this.form1.id = data.data.id
								} else {
									this.$message.error(data.msg);
									return;
								}
							}
						);
					} else {}
				});
			},

			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$post("/user-api/register/exp-update", this.form1).then(
							(data) => {
								if (data.code == 1000) {
									this.active = this.active + 1;
									console.log(this.active);
								} else {
									this.$message.error(data.msg);
									return;
								}
							}
						);
					} else {}
				});
			},
			CityChange(value) {
				this.form1.province = value[0];
				this.form1.city = value[1];
				this.form1.district = value[2];
			},
			// 获取省市区地址
			getProvince() {
				// 获取省市区地址
				this.$get("base-api/area/tree").then((data) => {
					this.cityOptions = this.getTreeData(data.data);
				});
			},
			// 递归判断列表，把最后的children设为undefined
			getTreeData(data) {
				for (var i = 0; i < data.length; i++) {
					if (data[i].child.length < 1) {
						// children若为空数组，则将children设为undefined
						data[i].child = undefined;
					} else {
						// children若不为空数组，则继续 递归调用 本方法
						this.getTreeData(data[i].child);
					}
				}
				return data;
			},
			handleAvatarSuccess(file) {
				this.imageUrl = configApi.photoURL + file.data;
				this.form1.head = file.data;
			},
			next() {
				this.$router.push({
					name: "diagnosis",
				});
			},
			getSms() {
				let _this = this;
				if (this.times != 60) {
					this.$message('请稍后再试')
					return
				}
				this.$post('/user-api/register/mobileCode/get?mobile=' + this.form.mobile).then(data => {
					if (data.code != 1000) {
						this.$message.error(data.msg)
						return
					}
					this.$message({
						message: "发送成功",
						type: "success",
					});
					timer = setInterval(function() {
						_this.smsText = _this.times + 's后重试'
						if (_this.times == 0) {
							_this.times = 60;
							clearInterval(timer);
							_this.smsText = '获取验证码'
						} else {
							_this.times--
						}
					}, 1000) //每隔1000毫秒弹一次
				})
			}

		},
		mounted() {
			if (this.$route.query.active) {
				this.active = Number(this.$route.query.active);
				this.form1.id = this.$route.query.id;
			}
			this.getProvince();
		}
	};
</script>

<style lang='scss' scoped>
	.register {
		background-color: #f5f5f5;
		width: 100vw;
		height: 100vh;

		.main {
			background-color: #fff;
			width: 1440px;
			height: 900px;
			box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
			border-radius: 4px;

			.fromBox {
				height: 600px;

				::v-deep .el-icon-circle-check {
					font-size: 60px;
					color: #8abbee;
				}

				.textSubim {
					font-size: 18px;
					font-weight: 500;
				}
			}

			.login {
				line-height: 108px;
				color: #359eff;
				margin-right: 30px;
			}

			.user {
				font-size: 18px;
				color: #999999;
			}

			.from {
				::v-deep .el-form-item__content {
					margin-left: 0 !important;
				}

				.duanxin {
					::v-deep .el-input {
						width: 234px;
					}

					::v-deep .el-button {
						margin-left: 6px;
					}
				}

				.text {
					color: #4a99f6;
				}

				width: 352px;

				::v-deep .el-cascader {
					display: block;
				}
			}

			::v-deep .step {
				height: 110px;
				width: auto;
				background-color: #f5f5f5;

				.steps {
					width: 800px;
					margin: 0 auto;
				}
			}
		}

		::v-deep .avatar-uploader .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}

		::v-deep .avatar-uploader .el-upload:hover {
			border-color: #409eff;
		}

		::v-deep .avatar-uploader-icon {
			font-size: 12px;
			color: #8c939d;
			width: 80px;
			height: 80px;
			line-height: 80px;
			text-align: center;
		}

		::v-deep .avatar {
			width: 80px;
			height: 80px;
			display: block;
		}
	}
</style>
